import { useState, useEffect } from "react";
import { Link, useRouteLoaderData, useParams } from "react-router-dom";
import Select from "react-select";

import { CountryInput, CountryCodeInput } from "./CountryInput";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { ComunaAPI } from "../managers/ComunaAPI";
import { ComunaStaffAPI } from "../managers/ComunaStaffAPI";
import Tag from "./Tags";
import { Loader } from "./loader";

import { ADMIN_URL } from "../config";
import ApplicationInterviewForm from "./ApplicationInterviewForm";
const clock_icon = require("../img/icons/clock-icon.png");

const juniorBadge = require("../img/icons/junior-badge.png");
const midBadge = require("../img/icons/mid-badge.png");
const seniorBadge = require("../img/icons/senior-badge.png");
const leadBadge = require("../img/icons/lead-badge.png");

interface ApplicationFormProps {
	application?: any;
}

function ValidatedInput({
	field,
	disabled,
	placeholder,
	contractor,
	validations,
	updateField,
}: any) {
	return (
		<input
			disabled={disabled}
			value={contractor[field]}
			className={"comuna-input " + (validations[field] ? "" : "comuna-error")}
			type="text"
			placeholder={placeholder}
			onChange={(e) => {
				updateField(field, e.target.value);
			}}
		/>
	);
}

const ApplicationForm: React.FC<ApplicationFormProps> = () => {
	const { application_id }: any = useParams();
	const { resource_request_source }: any = useRouteLoaderData(
		"resource_request_detail"
	);

	const closeIcon = require("../img/icons/x.png");
	const addImageIcon = require("../img/icons/image-plus.png");

	const [skillOptions, setSkillOptions] = useState<any[]>([]);
	const [selectedSkills, setSelectedSkills] = useState<any[]>([]);

	const [application, setApplication] = useState<any>({
		internal_notes: ""
	});

	const [contractor, setContractor] = useState<any>({
		first_name: "",
		last_name: "",
		title: "",
		email: "",
		phone_number: "",
		phone_country_indicator: "",
		salary_expectation: "",
		english_level: "1",
		seniority: "1",
		website: "",
		linkedin: "",
		cv: null,
		profile_picture: null,
		country_location: "",
		city_location: "",
		top_candidate: false,
		skills: "",
		note_for_client: "",
	});

	const [validations, setValidations] = useState<any>({
		first_name: true,
		last_name: true,
		title: true,
		email: true,
		phone_number: true,
		phone_country_indicator: true,
		salary_expectation: true,
		english_level: true,
		seniority: true,
		website: true,
		linkedin: true,
		cv: true,
		profile_picture: true,
		country_location: true,
		city_location: true,
		top_candidate: true,
		skills: true,
		note_for_client: true,
		internal_notes: true,
	});

	const [profilePicture, setProfilePicture] = useState<any>(null);
	const [CVFile, setCVFile] = useState<any>(null);
	const [CVFileName, setCVFileName] = useState<string>("");

	const [editMode, setEditMode] = useState(false);
	const [editing, setEditing] = useState(true);

	const [loading, setLoading] = useState(false);

	const updateContractorField = (field: string, value: any) => {
		setContractor({
			...contractor,
			[field]: value,
		});
	};

	const updateApplicationField = (field: string, value: any) => {
		setApplication({
			...application,
			[field]: value,
		});
	};

	const [skillsCheck, setSkillsCheck] = useState(true);

	const [tabSelected, setTabSelected] = useState("new"); //new or existing

	const tabStyle = "px-6 py-3 font-medium border-b-4 border-white";
	const tabSelectedStyle =
		"px-6 py-3 font-bold comuna-purple border-b-4 border-comuna-purple";

	const selectedSeniorityCard =
		"mb-2 rounded-lg p-4 w-full flex flex-col items-start bg-purple-dark";
	const deselectedSeniorityCard =
		"mb-2 rounded-lg p-4 w-full flex flex-col items-start bg-purple-light";

	// <option value="1">1 Basic</option>
	// <option value="2">2 B1</option>
	// <option value="3">3 B2</option>
	// <option value="4">4 C1</option>
	// <option value="5">5 C2</option>
	const englishLevelOptions = [
		{ value: "1", label: "1 Basic" },
		{ value: "2", label: "2 B1" },
		{ value: "3", label: "3 B2" },
		{ value: "4", label: "4 C1" },
		{ value: "5", label: "5 C2" },
	];

	useEffect(() => {
		InitForm();
	}, []);

	async function InitForm() {
		const token = await LocalStorageManager.getToken();
		const userResReqReturn = await ComunaAPI.getResReqFields(token);

		if (userResReqReturn != null) {
			var newSkillOptions: any[] = [];
			userResReqReturn.skills.map((item: any) => {
				const newSkill = { label: item.name, value: item.id };
				newSkillOptions.push(newSkill);
			});
			await setSkillOptions(newSkillOptions);
		}

		//are we in editing mode? do we have an existing app id?
		if (application_id) {
			resource_request_source.applications.forEach((item: any) => {
				if (item.id === parseInt(application_id)) {
					if (item.internal_notes === null) {
						item.internal_notes = "";
					}
					setApplication(item);
					var selectedSkills_: any[] = [];

					for (let i = 0; i < item.contractor.skills.length; i++) {
						newSkillOptions.forEach((option: any) => {
							if (option.label === item.contractor.skills[i]) {
								selectedSkills_.push(option);
							}
						});
					}

					setSelectedSkills(selectedSkills_);

					item.contractor.profile_id = item.contractor.id; //this way when we submit the form, the profile_id triggers and update and not a create
					
					setContractor(item.contractor);
					if (item.contractor.cv) {
						setCVFileName(item.contractor.cv);
					}
					setEditMode(true);
					setEditing(true);
				}
			});
		}

		//do we have a prefill true in the url?
		const urlParams = new URLSearchParams(window.location.search);
		const prefill = urlParams.get("prefill");
		if (prefill === "true") {
			PrefillForm();
		}
	}

	function PrefillForm() {
		const urlParams = new URLSearchParams(window.location.search);
		const name = urlParams.get("name");
		
		const names = name ? name.split(" ") : [];
		const firstName = names.shift() || "";
		const lastName = names.join(" ");
	
		const prefillContractor = {
			first_name: firstName,
			last_name: lastName,
			title: urlParams.get("role_title"),
			email: urlParams.get("email"),
			phone_number: urlParams.get("phone_number"),
			phone_country_indicator: urlParams.get("country"),
			salary_expectation: "",
			english_level: urlParams.get("english_level"),
			seniority: urlParams.get("seniority"),
			website: urlParams.get("website_url"),
			linkedin: urlParams.get("linkedin_url"),
			cv: null,
			profile_picture: null,
			country_location: urlParams.get("country"),
			city_location: urlParams.get("city"),
			top_candidate: false,
			skills: "",
			note_for_client: urlParams.get("skill_list"),
		};
		setContractor(prefillContractor);

		const prefillApplication = {
			internal_notes: urlParams.get("internal_notes") || "",
		};
		setApplication((prevApplication: any) => ({
			...prevApplication,
			...prefillApplication
		}));
	}

	function validateForm() {
		if (loading) return;

		const newValidations = {
			first_name: contractor.first_name !== "",
			last_name: contractor.last_name !== "",
			title: contractor.title !== "",
			email: contractor.email !== "",
			phone_number: contractor.phone_country_indicator !== "" ? contractor.phone_number !== "" : true, //contractor.phone_number !== "",
			phone_country_indicator: contractor.phone_number !== "" ? contractor.phone_country_indicator !== "": true,
			salary_expectation: contractor.salary_expectation !== "",
			english_level: contractor.english_level !== "",
			seniority: contractor.seniority !== "",
			website: true,
			linkedin: true,
			cv: true,
			profile_picture: contractor.profile_picture !== "",
			country_location: true,
				//contractor.country_location !== "" &&
				//contractor.country_location !== "Select Country",
			city_location: true,
			top_candidate: contractor.top_candidate !== "",
			skills: true, // contractor.skills !== "",
			note_for_client: contractor.note_for_client !== "",
			internal_notes: true
		};
		setValidations(newValidations);
		//setSkillsCheck(contractor.skills.length > 0);

		//check if all validations are true
		var allValid = true;
		for (const [key, value] of Object.entries(newValidations)) {
			if (!value) {
				allValid = false;
			}
		}
		if (allValid) {
			SubmitForm();
		} else {
			alert("Please fill all the required fields");
		}
	}

	async function SubmitForm() {
		setLoading(true);
		const token = await LocalStorageManager.getToken();
	
		const contractorData = contractor;
		const skills_id: number[] = [];
		selectedSkills.forEach((item: any) => {
			skills_id.push(item.value);
		});
		contractorData.skills = skills_id;
	
		let applicationResult;
		if (editMode) {
			// Update contractor and application if we are in edit mode
			const contractorResult = await ComunaStaffAPI.CreateApplication(token, resource_request_source.id, contractorData);
			if (contractorResult) {
				const applicationData = {
					id: application.id,
					contractor: contractor.id,
					state: application.state,
					interview_calendar_link: application.interview_calendar_link,
					interview_available_dates: application.interview_available_dates,
					interview_accepted_date: application.interview_accepted_date,
					interview_call_link: application.interview_call_link,
					resource_request: application.resource_request,
					internal_notes: application.internal_notes
				};
				console.log("response", applicationData);
				console.log("response", application.id);
				applicationResult = await ComunaStaffAPI.UpdateApplication(token, application.id, applicationData);
				if (applicationResult) {
					alert("Contractor and Application were updated successfully");
				} else {
					alert("There was an error updating the application");
				}
			} else {
				alert("There was an error updating the contractor");
			}
		} else {
			// Create contractor and application
			const creationResult = await ComunaStaffAPI.CreateApplication(token, resource_request_source.id, contractorData);
			if (creationResult && creationResult.id) {
				const applicationData = {
					id: application.id,
					contractor: contractor.id,
					state: application.state,
					interview_calendar_link: application.interview_calendar_link,
					interview_available_dates: application.interview_available_dates,
					interview_accepted_date: application.interview_accepted_date,
					interview_call_link: application.interview_call_link,
					resource_request: application.resource_request,
					internal_notes: application.internal_notes
				};

				applicationResult = await ComunaStaffAPI.UpdateApplication(token, creationResult.id, applicationData);
				if (applicationResult) {
					alert("New Contractor and Application were created successfully");
				} else {
					alert("Contractor was created but there was an error creating the application");
				}
			} else {
				alert("There was an error creating the contractor and application");
			}
		}
	
		setLoading(false);
		if (applicationResult) {
			window.location.reload();
		}
	}

	const customFilterOption = (option: any, searchText: any) => {
		if (!searchText) {
		  return true; // Show all options if search text is empty
		}
		
		const normalizeText = (text: any) => text.toLowerCase().replace(/\s+/g, '');
		const normalizedSearchText = normalizeText(searchText);
		const normalizedOptionLabel = normalizeText(option.label);
	  
		return normalizedOptionLabel.includes(normalizedSearchText);
	  };	  

	return (
		<div className="comuna-popup fixed">
			<div
				className="comuna-card padding relative"
				style={{
					overflow: "initial",
					width: "55%",
					maxWidth: 1500,
					minWidth: 800,
				}}>
				<Link
					to={application_id === undefined ? ("/requests/" + resource_request_source.id) : ("/requests/" + resource_request_source.id +"/application/"+application_id) }
					style={{ position: "absolute", top: 20, right: 20 }}>
					<img src={closeIcon} style={{ width: 24, height: "auto" }} alt="" />
				</Link>
				{!editMode && (
					<h1 className="font-bold text-3xl text-left mb-6">Add candidate</h1>
				)}
				{editMode && (
					<h1 className="font-bold text-3xl text-left mb-6">
						Edit application
					</h1>
				)}

				{!editMode && (
					<div
						className="w-full border-gray-3"
						style={{ borderBottomWidth: 3 }}>
						<button
							onClick={() => setTabSelected("new")}
							className={
								"ml-6 " + (tabSelected === "new" ? tabSelectedStyle : tabStyle)
							}>
							New candidate
						</button>
						<button
							onClick={() => setTabSelected("existing")}
							className={
								tabSelected === "existing" ? tabSelectedStyle : tabStyle
							}>
							Existing candidate
						</button>
					</div>
				)}

				{tabSelected === "new" && (
					<div>
						{application != null && (
							<ApplicationInterviewForm application={application} />
						)}

						<div className="flex flex-row justify-between items-center bg-purple-light mb-3 rounded-lg p-4 mt-8">
							{/** Image preview and Picker */}
							<div
								className="flex flex-col bg-white rounded-full items-center"
								style={{
									width: 120,
									height: 120,
								}}>
								{contractor.profile_picture ? (
									<div className="bg-white">
										{typeof contractor.profile_picture === "string" ? (
											<img
												style={{
													width: 120,
													height: 120,
													borderRadius: "50%",
													overflow: "hidden",
												}}
												src={contractor.profile_picture}
												alt={"preview of profile"}
											/>
										) : (
											<img
												style={{
													width: 120,
													height: 120,
													borderRadius: "50%",
													overflow: "hidden",
												}}
												src={URL.createObjectURL(contractor.profile_picture)}
												alt={"preview of profile"}
											/>
										)}
									</div>
								) : (
									<img
										className="muna-icon-big-white xl bg-white"
										style={{
											width: 70,
											height: 70,
											marginTop: 10,
											marginBottom: -10,
										}}
										src={addImageIcon}
										alt="gray background and the number 120x120 in darker gray letters"
									/>
								)}
								<input
									id="image_input"
									type="file"
									name="myImage"
									style={{ display: "none" }}
									onChange={(event) => {
										setProfilePicture(event.target.files![0]);
										updateContractorField("profile_picture", event.target.files![0]);
									}}
								/>
								{editing && (
									<button
										className="comuna-white-button xs mx-auto mt-2 text-xs"
										style={{
											height: 30,
											marginTop: -10,
											marginLeft: -5,
											width: 130,
											backgroundColor: "transparent",
										}}
										onClick={() => {
											document.getElementById("image_input")?.click();
										}}>
										{"Add candidate" + "\n" + "photo"}
									</button>
								)}
							</div>
							{/** Contractor details preview */}
							<div className="flex flex-col justify-center ml-4 flex-1">
								<span className="font-bold text-xl">
									{contractor.first_name + " " + contractor.last_name}
								</span>
								<span className="text-gray-2">{contractor.title}</span>
								<div className="flex flex-row mt-2">
									{contractor.top_candidate && <Tag tag="top-candidate" />}
									<Tag tag={"s-" + contractor.seniority} />
								</div>
							</div>
							<div className="flex flex-col justify-center">
								{/* {CVFileName !== "" && (
									<a
										className="comuna-label-input"
										style={{
											width: "10em",
											height: "2em",
											fontWeight: "bold",
											textOverflow: "ellipsis",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textAlign: "center",
										}}
										href={CVFileName}>
										CV: {CVFileName}
									</a>
								)} */}

								{CVFileName !== "" && (
									<div className="text-center">
										<button
											className="comuna-secondary-button font-medium color-gray-3 ml-4"
											onClick={() => {
												window.open(CVFileName);
											}}>
											Check CV
										</button>
									</div>
								)}

								{editing && contractor.cv && (
									<div className="text-center">
										<button
											className="font-medium color-gray-3 ml-4"
											onClick={() => {
												updateContractorField("cv", null);
												setCVFileName("");
											}}>
											Clear
										</button>
									</div>
								)}
								<input
									id="file_input"
									type="file"
									name="myFile"
									style={{ display: "none" }}
									onChange={(event) => {
										setCVFile(event.target.files![0]);
										updateContractorField("cv", event.target.files![0]);
										setCVFileName(event.target.files![0].name);
									}}
								/>
								{editing && (
									<button
										className="comuna-secondary-button xs mx-auto mt-2"
										onClick={() => {
											document.getElementById("file_input")?.click();
										}}>
										Pick Resume File
									</button>
								)}
							</div>
						</div>

						{!validations.profile_picture && (
							<p className="text-red-400">*Provide a profile picture</p>
						)}

						<div className="flex flex-row">
							<div className="flex flex-col w-full">
								<span className="comuna-input-label">First name</span>
								<ValidatedInput
									placeholder="Juan"
									field="first_name"
									contractor={contractor}
									validations={validations}
									updateField={updateContractorField}
									disabled={!editing}
								/>
							</div>
							<div className="flex flex-col w-full mx-4">
								<span className="comuna-input-label">Last name</span>
								<ValidatedInput
									placeholder="Rodriguez"
									field="last_name"
									contractor={contractor}
									validations={validations}
									updateField={updateContractorField}
									disabled={!editing}
								/>
							</div>
							<div className="flex flex-col w-full">
								<span className="comuna-input-label">Title</span>
								<ValidatedInput
									placeholder="Developer"
									field="title"
									contractor={contractor}
									validations={validations}
									updateField={updateContractorField}
									disabled={!editing}
								/>
							</div>
						</div>

						<div className="flex flex-row">
							<div className="flex flex-col w-full">
								<span className="comuna-input-label">Email</span>
								<ValidatedInput
									placeholder="juan@email.com"
									field="email"
									contractor={contractor}
									validations={validations}
									updateField={updateContractorField}
									disabled={!editing}
								/>
							</div>

							<div className="flex flex-col w-full ml-4">
								<span className="comuna-input-label">Country Indicator</span>
								<CountryCodeInput
									initialValue={contractor.phone_country_indicator}
									error={!validations.phone_country_indicator}
									onChange={(v) => {
										updateContractorField("phone_country_indicator", v);
									}}
									disabled={!editing}
								/>
							</div>

							<div className="flex flex-col w-full ml-4">
								<span className="comuna-input-label">Phone</span>
								<ValidatedInput
									placeholder="300 000 00 00"
									field="phone_number"
									contractor={contractor}
									validations={validations}
									updateField={updateContractorField}
									disabled={!editing}
								/>
							</div>
						</div>

						<div className="flex flex-row">
							<div className="flex flex-col w-full">
								<span className="comuna-input-label">Salary Expectation</span>
								<input
									className={
										"comuna-input " +
										(validations["salary_expectation"] ? "" : "comuna-error")
									}
									type="number"
									placeholder="10000"
									min={10000}
									value={contractor.salary_expectation}
									onChange={(e) => {
										updateContractorField("salary_expectation", e.target.value);
									}}
									disabled={!editing}
								/>
							</div>
							<div className="flex flex-col w-full ml-4">
								<span className="comuna-input-label">English Level</span>
								{editing ? (
									<Select
										className="comuna-select bg-white"
										classNames={{
											menuList:(state)=>"bg-white"
										}}
										value={englishLevelOptions.find(x=>x.value===contractor.english_level.toString()) }
										options={englishLevelOptions}
										onChange={(e) => {
											updateContractorField("english_level", e?.value);
										}}
									/>
								) : (
									<div className="comuna-input">{contractor.english_level}</div>
								)}
							</div>
						</div>

						{/* <div className="flex flex-row">
							<div className="flex flex-col w-full">
								<span className="comuna-input-label">Seniority</span>
								<select
									className="comuna-input"
									value={contractor.seniority}
									onChange={(e) => {
										updateField("seniority", e.target.value);
									}}
									disabled={!editing}>
									<option value="1">Junior</option>
									<option value="2">Mid-Level</option>
									<option value="3">Senior</option>
									<option value="4">Lead</option>
								</select>
							</div>
						</div> */}

						<div className="flex flex-col w-full">
							<span className="comuna-input-label">Seniority</span>

							<div className="flex flex-row">
								<button
									onClick={(e) => {
										updateContractorField("seniority", "1");
									}}
									className={
										(contractor.seniority === "1"
											? selectedSeniorityCard
											: deselectedSeniorityCard) + " mr-4"
									}>
									<div className="flex flex-row w-full justify-between">
										<img
											src={juniorBadge}
											alt="four triangles indicating level"
											style={{ height: 24, marginRight: 12 }}
										/>
										<input
											type="radio"
											name="seniority"
											value="Junior"
											checked={contractor.seniority.toString() === "1"}
											onClick={(e) => {
												updateContractorField("seniority", "1");
											}}
											disabled={!editing}
										/>
									</div>
									<b>Junior</b>
									<b className="font-normal text-xs">1-3 years of experience</b>
								</button>
								<button
									onClick={(e) => {
										updateContractorField("seniority", "2");
									}}
									className={
										(contractor.seniority === "2"
											? selectedSeniorityCard
											: deselectedSeniorityCard) + " mr-4"
									}>
									<div className="flex flex-row w-full justify-between">
										<img
											src={midBadge}
											alt="four triangles indicating level"
											style={{ height: 24, marginRight: 12 }}
										/>
										<input
											type="radio"
											name="seniority"
											value="Mid-level"
											checked={contractor.seniority.toString() === "2"}
											onClick={(e) => {
												updateContractorField("seniority", "2");
											}}
											disabled={!editing}
										/>
									</div>
									<b>Mid-level</b>
									<b className="font-normal text-xs">3-6 years of experience</b>
								</button>
								<button
									onClick={(e) => {
										updateContractorField("seniority", "3");
									}}
									className={
										(contractor.seniority === "3"
											? selectedSeniorityCard
											: deselectedSeniorityCard) + " mr-4"
									}>
									<div className="flex flex-row w-full justify-between">
										<img
											src={seniorBadge}
											alt="four triangles indicating level"
											style={{ height: 24, marginRight: 12 }}
										/>
										<input
											type="radio"
											name="seniority"
											value="Senior"
											checked={contractor.seniority.toString() === "3"}
											onClick={(e) => {
												updateContractorField("seniority", "3");
											}}
											disabled={!editing}
										/>
									</div>
									<b>Senior</b>
									<b className="font-normal text-xs">
										6-10 years of experience
									</b>
								</button>
								<button
									onClick={(e) => {
										updateContractorField("seniority", "4");
									}}
									className={
										contractor.seniority === "4"
											? selectedSeniorityCard
											: deselectedSeniorityCard
									}>
									<div className="flex flex-row w-full justify-between">
										<img
											src={leadBadge}
											alt="four triangles indicating level"
											style={{ height: 24, marginRight: 12 }}
										/>
										<input
											type="radio"
											name="seniority"
											value="Lead"
											checked={contractor.seniority.toString() === "4"}
											onClick={(e) => {
												updateContractorField("seniority", "4");
											}}
											disabled={!editing}
										/>
									</div>
									<b>Lead</b>
									<b className="font-normal text-xs">
										6-10 years of experience
									</b>
								</button>
							</div>
						</div>

						<div className="flex flex-row">
							<div className="flex flex-col w-full">
								<span className="comuna-input-label">Website link</span>
								<ValidatedInput
									placeholder="example.com"
									field="website"
									contractor={contractor}
									validations={validations}
									updateField={updateContractorField}
									disabled={!editing}
								/>
							</div>
							<div className="flex flex-col w-full ml-4">
								<span className="comuna-input-label">Linkedin profile</span>
								<ValidatedInput
									placeholder="example.com"
									field="linkedin"
									contractor={contractor}
									validations={validations}
									updateField={updateContractorField}
									disabled={!editing}
								/>
							</div>
						</div>

						<div className="flex flex-row">
							<div className="flex flex-col w-full mr-4">
								<span className="comuna-input-label">Country</span>
								<CountryInput
									initialValue={contractor.country_location}
									error={!validations.country_location}
									onChange={(value) => {
										updateContractorField("country_location", value);
									}}
									disabled={!editing}
								/>
							</div>
							<div className="flex flex-col w-full">
								<span className="comuna-input-label">City</span>
								<ValidatedInput
									placeholder="city"
									field="city_location"
									contractor={contractor}
									validations={validations}
									updateField={updateContractorField}
									disabled={!editing}
								/>
							</div>
						</div>

						<span className="comuna-input-label">Is it a top candidate?</span>
						<div className="flex flex-row">
							<div
								className={
									"flex flex-row comuna-input mr-4 " +
									(contractor.top_candidate ? "comuna-selected" : "")
								}>
								<input
									type="radio"
									name="top_candidate"
									value="yes"
									onClick={(e) => {
										updateContractorField("top_candidate", true);
									}}
									disabled={!editing}
								/>
								<label className="ml-2">Yes</label>
							</div>
							<div
								className={
									"flex flex-row comuna-input " +
									(contractor.top_candidate ? "" : "comuna-selected")
								}>
								<input
									type="radio"
									name="top_candidate"
									value="no"
									onClick={(e) => {
										updateContractorField("top_candidate", false);
									}}
									disabled={!editing}
								/>
								<label className="ml-2">No</label>
							</div>
						</div>

						<span className="comuna-input-label">Skills</span>
						<Select
							isDisabled={!editing}
							className={skillsCheck ? "" : "comuna-error"}
							options={skillOptions}
							isMulti
							value={selectedSkills}
							filterOption={customFilterOption}
							onChange={(data) => {
								const a: any[] = [];
								data.map((item: any) => {
									a.push(item.value);
								});
								updateContractorField("skills", a);
								setSelectedSkills([...data]);
							}}></Select>

						<span className="comuna-input-label">Notes for the client</span>
						<textarea
							disabled={!editing}
							value={contractor.note_for_client}
							onChange={(e) => {
								updateContractorField("note_for_client", e.target.value);
							}}
							className={
								"comuna-input " +
								(validations["note_for_client"] ? "" : "comuna-error")
							}
							placeholder="Notes for the client"
							rows={4}
						/>

						<span className="comuna-input-label">Internal notes</span>
						<textarea
							disabled={!editing}
							value={application.internal_notes}
							onChange={(e) => {
								updateApplicationField("internal_notes", e.target.value);
							}}
							className={
								"comuna-input " +
								(validations["internal_notes"] ? "" : "comuna-error")
							}
							placeholder="Internal notes of the application"
							rows={4}
						/>

						{/** Edit buttons */}
						{editing && (
							<div className="flex flex-row mt-4">
								
								<button
									className="comuna-secondary-button mr-4"
									onClick={() => {
										window.history.go(-1);
									}}>
									Cancel
								</button>
								<button
									className="comuna-primary-button"
									onClick={validateForm}>
									{!loading && (
										<span>
											{editMode ? "Save changes" : "Add this candidate"}{" "}
										</span>
									)}
									{loading && <Loader />}
								</button>
								{application_id && (
									<a
										className="comuna-special-button ml-4"
										target="blank"
										href={
											ADMIN_URL +
											"core/application/" +
											application_id +
											"/change/"
										}>
										Edit in Django
									</a>
								)}
							</div>
						)}
					</div>
				)}

				{tabSelected === "existing" && (
					<div>
						<p className="p-12 py-16 text-center">
							You cannot add existing candidates to an application yet
						</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default ApplicationForm;
